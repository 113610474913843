export default [
	{
        path: '/about',
        name: 'about',
        component: () => import('@/layouts/master'),
        meta: {
            title: 'About',
        },
        children: [
            {
                path: '',
                component: () => import('@/modules/about/pages/about'),
                name: 'about',
            },
        ],
    },
];
