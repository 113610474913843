export default [
	{
        path: '/contact',
        name: 'contact',
        component: () => import('@/layouts/master'),
        meta: {
            title: 'Contact',
        },
        children: [
            {
                path: '',
                component: () => import('@/modules/contact/pages/contact'),
                name: 'contact',
            },
        ],
    },
];
