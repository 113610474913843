export default [
	{
        path: '/',
        name: 'home',
        component: () => import('@/layouts/master'),
        meta: {
            title: 'Home',
        },
        children: [
            {
                path: '',
                component: () => import('@/modules/home/pages/home'),
                name: 'home',
            },
        ],
    },
];
