export default [
    {
        path: '/portfolio',
        name: 'portfolio',
        component: () => import('@/layouts/master'),
        meta: {
            title: 'Portfolio',
        },
        children: [
            {
                path: '',
                component: () => import('@/modules/portfolio/pages/portfolio'),
                name: 'portfolio',
            },
            {
                path: 'bitelife',
                component: () => import('@/modules/portfolio/pages/bitelife'),
                name: 'bitelife',
                meta: {
                    title: 'Bitelife',
                },
            }, {
                path: 'bitelife-admin',
                component: () => import('@/modules/portfolio/pages/bitelifeAdmin'),
                name: 'bitelife-admin',
                meta: {
                    title: 'Bitelife Admin',
                },
            }, {
                path: '/portfolio/meritocracy',
                component: () => import('@/modules/portfolio/pages/meritocracy'),
                name: 'meritocracy',
                meta: {
                    title: 'Meritocracy',
                },
            }, {
                path: 'timerly',
                component: () => import('@/modules/portfolio/pages/timerly'),
                name: 'timerly',
                meta: {
                    title: 'Timerly',
                },
            }
        ],
    },
];
