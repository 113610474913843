import {createWebHistory, createRouter} from "vue-router";

import homeRoutes from '@/modules/home/routes.js';
import projectRoutes from '@/modules/portfolio/routes.js';
import aboutRoutes from '@/modules/about/routes.js';
import contactRoutes from '@/modules/contact/routes.js';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...homeRoutes,
        ...projectRoutes,
        ...aboutRoutes,
        ...contactRoutes
    ]
});

export default router;